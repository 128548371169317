<template>
  <div class="container">
    <b-card
      v-if="hide_form"
    >
      <b-row class="vx-row mx-5 mt-4 mb-4">
        <h6>Chatbot Status: {{ form_data.status }}</h6>
      </b-row>

      <b-row class="vx-row mx-5 mt-8 mb-4">
        <h6 class="underline">
          Previous Responses
        </h6>
      </b-row>

      <b-row
        v-if="form_data.onboard_data.bot_type == 'enterprise'"
        class="vx-row mx-5 mt-4 mb-4"
      >
        <h6>Type of bot : Enterprise Recruitment Chatbot</h6>
      </b-row>

      <b-row
        v-if="form_data.onboard_data.bot_type == 'community'"
        class="vx-row mx-5 mt-4 mb-4"
      >
        <h6>Type of bot : Community Recruitment Chatbot</h6>
      </b-row>

      <b-row
        v-if="form_data.onboard_data.bot_name.length > 3"
        class="vx-row mx-5 mt-4 mb-4"
      >
        <h6>
          Desired name for the bot : {{ form_data.onboard_data.bot_name }}
        </h6>
      </b-row>

      <b-row
        v-if="form_data.onboard_data.bot_username.length > 3"
        class="vx-row mx-5 mt-4 mb-4"
      >
        <h6>
          Desired username for the bot :
          {{ form_data.onboard_data.bot_username }}
        </h6>
      </b-row>

      <b-row
        v-if="form_data.onboard_data.have_channel == true"
        class="vx-row mx-5 mt-4 mb-4"
      >
        <h6>You own corporate owned channel : Yes</h6>
      </b-row>

      <b-row
        v-if="form_data.onboard_data.have_channel == true"
        class="vx-row mx-5 mt-4 mb-4"
      >
        <h6 v-if="form_data.onboard_data.allow_channel == true">
          You want the channel to be added to the service : Yes
        </h6>
        <h6 v-else>
          You want the channel to be added to the service : No
        </h6>
      </b-row>
    </b-card>
    <b-card v-else>
      <b-row class="vx-row mx-1 mt-2">
        <h6 class="">
          1. Choose your chatbot type you desire
        </h6>
      </b-row>

      <b-row class="vx-row">
        <div class="chatbot-table mx-1">
          <div
            class="enterprise py-6 px-4 border-solid border-r-1 border-t-1 border-l-1 border-b-2 border-gray-400"
          >
            <b-form-radio
              v-model="onboard_data.bot_type"
              class="font-bold"
              value="enterprise"
            >Enterprise Recruitment Chatbot(S$399/month)</b-form-radio>
          </div>

          <div
            class="service-spec text-center py-16 border-solid border-r-0 border-t-1 border-l-2 border-b-2 border-gray-400"
          >
            <h6 class="font-bold py-16">
              Service Specifications
            </h6>
          </div>
          <div
            class="branding text-center py-12 border-solid border-r-0 border-t-1 border-l-2 border-b-2 border-gray-400"
          >
            <h6 class="font-bold">
              Branding
            </h6>
          </div>
          <div
            class="technical text-center py-12 border-solid border-r-0 border-t-0 border-l-2 border-b-2 border-gray-400"
          >
            <h6 class="font-bold">
              Technical
            </h6>
          </div>
          <div
            class="ent1 px-4 py-12 border-solid border-r-1 border-t-0 border-l-2 border-b-2 border-gray-400"
          >
            <h6>Customisable with your organisation brand</h6>
          </div>

          <div class="ent2">
            <div
              class="ent3 px-4 py-4 border-solid border-r-1 border-t-0 border-l-2 border-b-2 border-gray-400"
            >
              <h6>Dedicated and prioritised end-user IT Support chat</h6>
            </div>
            <div
              class="ent4 px-4 py-4 border-solid border-r-1 border-t-0 border-l-2 border-b-2 border-gray-400"
            >
              <h6>Customisable System Process</h6>
            </div>
          </div>
          <div class="comm2" />
        </div>
      </b-row>

      <b-row
        v-if="
          onboard_data.bot_type == 'community' ||
            onboard_data.bot_type == 'enterprise'
        "
        class="vx-row w-full mx-auto my-12"
      >
        <feather-icon
          class="mx-auto"
          icon="ArrowDownCircleIcon"
        />
      </b-row>

      <b-row
        v-if="onboard_data.bot_type == 'community'"
        class="vx-row mx-5 mt-4"
      >
        <h6 class="mr-8">
          2. Do you have a corporate owned channel?
        </h6>
        <b-form-checkbox
          v-model="onboard_data.have_channel"
          switch
          inline
        />
      </b-row>

      <b-row
        v-if="
          onboard_data.bot_type == 'community' &&
            onboard_data.have_channel == true
        "
        class="vx-row w-full mx-auto my-12"
      >
        <feather-icon
          class="mx-auto"
          icon="ArrowDownCircleIcon"
        />
      </b-row>

      <b-row
        v-if="
          onboard_data.bot_type == 'community' &&
            onboard_data.have_channel == true
        "
        class="vx-row mx-5 mt-4"
      >
        <h6 class="mr-8">
          3. Do you want to include it in the service?
        </h6>
        <b-form-checkbox
          v-model="onboard_data.allow_channel"
          switch
          inline
        />
      </b-row>
      <b-row
        v-if="
          onboard_data.bot_type == 'community' &&
            onboard_data.have_channel == true
        "
        class="vx-row w-full mx-auto my-12"
      >
        <feather-icon
          class="mx-auto"
          icon="ArrowDownCircleIcon"
        />
      </b-row>

      <b-row
        v-if="
          onboard_data.bot_type == 'community' &&
            onboard_data.have_channel == true
        "
        class="vx-row mx-5 mt-4"
      >
        <h6 class="">
          3. When are you be available for the onboarding call? (Optional)
        </h6>
      </b-row>

      <vue-calendly
        v-if="
          onboard_data.bot_type == 'community' &&
            onboard_data.have_channel == true
        "
        url="https://calendly.com/headhuntershq/onboardingcall?primary_color=1d4886"
        :height="700"
      />

      <div
        v-if="
          onboard_data.bot_type == 'community' &&
            onboard_data.have_channel == true
        "
        class="vx-row w-full mx-auto my-12"
      >
        <feather-icon
          class="mx-auto"
          icon="ArrowDownCircleIcon"
        />
      </div>

      <div
        v-if="onboard_data.bot_type == 'enterprise'"
        class="vx-row mx-5 mt-4"
      >
        <h6 class="">
          2. Type in the desired name/username of your bot
        </h6>
      </div>
      <div
        v-if="onboard_data.bot_type == 'enterprise'"
        class="vx-row mt-4 mx-5"
      >
        <div class="vx-col w-1/2">
          <b-form-input
            v-model="onboard_data.bot_name"
            class="w-full"
            required
            placeholder="Type in the desired name of your bot"
          />
        </div>
        <div class="vx-col w-1/2">
          <b-form-input
            v-model="onboard_data.bot_username"
            class="w-full"
            required
            placeholder="Type in the desired username of your bot"
          />
        </div>
      </div>

      <div
        v-if="
          onboard_data.bot_type == 'enterprise' &&
            onboard_data.bot_name.length > 3
        "
        class="vx-row w-full mx-auto my-12"
      >
        <feather-icon
          class="mx-auto"
          icon="ArrowDownCircleIcon"
        />
      </div>

      <div
        v-if="
          onboard_data.bot_type == 'enterprise' &&
            onboard_data.bot_name.length > 3
        "
        class="vx-row mx-5 mt-4"
      >
        <h6 class="">
          3. When are you be available for the onboarding call?
        </h6>
      </div>

      <vue-calendly
        v-if="
          onboard_data.bot_type == 'enterprise' &&
            onboard_data.bot_name.length > 3
        "
        url="https://calendly.com/headhuntershq/onboardingcall?primary_color=1d4886"
        :height="700"
      />

      <div
        v-if="onboard_data.bot_type == 'enterprise'"
        class="vx-row w-full mx-auto my-12"
      >
        <feather-icon
          class="mx-auto"
          icon="ArrowDownCircleIcon"
        />
      </div>

      <div
        v-if="
          onboard_data.bot_name.length > 3 || onboard_data.have_channel == true
        "
        class="vx-row mt-12"
      >
        <b-button
          type="filled"
          variant="warning"
          class="mx-auto"
          @click="saveChatbotData"
        >Submit</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  VBModal,
  BBadge,
  BAvatar,
  BOverlay,
  BFormCheckbox,
  BFormInvalidFeedback,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    VBModal,
    BBadge,
    BAvatar,
    BOverlay,
    BFormCheckbox,
    BFormInvalidFeedback,
  },
  data() {
    return {
      form_data: {},
      hide_form: false,
      onboard_data: {
        bot_type: '',
        bot_name: '',
        bot_username: '',
        have_channel: false,
        allow_channel: false,
      },
      isLoading: true,
    }
  },

  created() {
    const { token } = this.$store.state.auth.ActiveUser
    this.$http.defaults.headers.common.Authorization = `Token ${token}`
    this.getChatbotData()
  },

  methods: {
    getChatbotData() {
      this.isLoading = true
      this.$http
        .get('/api/chatbot')
        .then(response => {
          const { success } = response.data
          if (success) {
            this.form_data = response.data.output
            this.hide_form = true
          } else {
            this.hide_form = false
          }
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Recruitment Chatbot',
                icon: 'AlertCircleIcon',
                text: 'Your responses have not been recorded. Try again later!',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
    },
    saveChatbotData() {
      this.isLoading = true
      const all_data = this.onboard_data

      this.$http
        .post('/api/chatbot', { data: all_data })
        .then(response => {
          const { success } = response.data
          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Recruitment Chatbot',
                  icon: 'AlertCircleIcon',
                  text: 'Your responses have been recorded successfully',
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
            this.isLoading = false
            this.getChatbotData()
          }
        })
        .catch(error => {
          this.isLoading = false
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Recruitment Chatbot',
                icon: 'AlertCircleIcon',
                text: 'Your responses have not been recorded. Try again later!',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
    },
  },
}
</script>
<style scoped>
.chatbot-table {
  display: grid;
  grid-template-columns: 2fr 1fr 3fr 0fr;
  grid-template-rows: 0.5fr 0.5fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    '. . enterprise community'
    'service-spec branding ent1 comm1'
    'service-spec technical ent2 comm2';
}

.enterprise {
  grid-area: enterprise;
}

.community {
  grid-area: community;
}

.service-spec {
  grid-area: service-spec;
}

.branding {
  grid-area: branding;
}

.technical {
  grid-area: technical;
}

.ent1 {
  grid-area: ent1;
}

.comm1 {
  grid-area: comm1;
}

.ent2 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    'ent3'
    'ent4';
  grid-area: ent2;
}

.ent3 {
  grid-area: ent3;
}

.ent4 {
  grid-area: ent4;
}

.comm2 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    'comm3'
    'comm4';
  grid-area: comm2;
}

.comm3 {
  grid-area: comm3;
}

.comm4 {
  grid-area: comm4;
}
</style>
